import ReactFullpage from "@fullpage/react-fullpage";
import { Header } from "./Header";
import { Recommended } from "./Recommended";
import { Begin } from "./Begin";
import { Contact } from "./Contact";

const MainPage = () => {
  return (
    <>
    
        <ReactFullpage
            scrollingSpeed = {1000}
            scrollHorizontally = {true}
            // verticalCentered={true} 
            navigation={true}
            // scrollHorizontallyKey = {'YOUR KEY HERE'}

            render={({ state, fullpageApi }) => {
              return (
                <>
                  
                    <ReactFullpage.Wrapper>
                      <div className="section w-full h-full bg-mountain bg-cover bg-no-repeat bg-center relative" id="fp-table section1">
                      {
                        <div className='w-full h-full absolute top-0 left-0 z-10 bg-gr1' ></div>
                        
                      }
                      {
                        <div className='w-full h-full absolute top-0 left-0 z-20 bg-gr2' ></div>
                        
                      }
                      {
                        <div className='w-full h-full absolute top-0 left-0 z-30 bg-gr3' ></div>
                        
                      }
                          <Header />
                      </div>
                      <div className="section" id="fp-table">
                          <Recommended />
                      </div>
                      <div className="section">
                          <Begin />
                      </div>
                      <div className="section">
                          <Contact />
                      </div>
                    </ReactFullpage.Wrapper>
                  </>
            );
            }}
  />
    </>
  )
}

export default MainPage

import React, { useContext, useRef, useState } from 'react'
import { Nav } from '../../components/Nav'
import { Footer } from '../../components/footer/footer'
import { Link, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import NextArrow from '../../components/NextArrow'
import PrevArrow from '../../components/PrevArrow'
import { TranslateText } from '../../context/TranslateText'
import { LanguageContext } from '../../context/lang'


const LocationPage = () => {
    const sliderRef = useRef(null)
    const { language } = useContext(LanguageContext);
    const {state} = useLocation()
    if (!state) {
        window.history.back()
    } else {
        const handleNext = () => {
          sliderRef.current.slickNext();
        };
      
        const handlePrev = () => {
          sliderRef.current.slickPrev();
        };
      
        const settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow onClick={handleNext} className2='slick-recom-next' />,
          prevArrow: <PrevArrow onClick={handlePrev} className2='slick-recom-prev' />
        };
        
        return (
            <div>
                <Nav version={2} />
                <div className='max-w-[1440px] mx-auto'>
                        <div className='container grid md:grid-cols-2 grid-cols-1 2xl:gap-[100px] lg:gap-[50px] gap-2 items-center my-[50px]'>
                            <div className='md:hidden block mb-4'>
                                <img  src={state.img_list[0].img} alt={`${state.title}_image`} className='w-full'/>
                            </div>
                            <div className='data'>
                                <h2 className='font-ibm xl:text-[51px] lg:text-[30px] md:text-[24px] text-[28px] font-semibold text-main capitalize'><TranslateText dictionary={state.title} /> </h2>
                                <p className='xl:text-base text-[14px] font-monserrat font-normal xl:my-[20px] my-[10px] xl:pr-[100px] lg:pr-[20px] pr-0'><TranslateText dictionary={state.sup_text} /></p>
                                
                                <div className='md:hidden block md:mt-24 mt-12 max-h-[300px] mb-[30px] '>
                                    <Slider ref={sliderRef} {...settings} className='capitalize lg:max-w-[75%] w-full ml-auto font-ibm location-slider relative py-2 max-h-[590px]'>
                                    {
                                        state.img_list.map((item) => (
                                        <div key={item.id} className='max-h-[300px]'>
                                            <img src={item.img} alt='misr_image' className=' bg-center rounded-[10px]' />
                                        </div>
                                        ))
                                    }
                                    </Slider>
                                </div>
                                
                                <h3 className='text-main font-ibm lg:text-[24px] text-[20px] font-semibold lg:mt-[35px] mt-[10px] xl:mb-[20px] mb-[10px]'  dangerouslySetInnerHTML={{__html: state.subtitle[language]}} />
                                <p className='xl:text-base text-[14px] font-monserrat font-normal xl:pr-[100px] lg:pr-[20px] pr-2'><TranslateText dictionary={state.sub_text} /></p>
                                <br />
                                <Link to="/order" state={state} className='bg-active-purple font-montserrat font-medium lg:text-[16px] text-[14px] capitalize text-white px-[33px] py-[11px] rounded-full'>
                                    <TranslateText dictionary={{
                                        en:"book",
                                        ru:"забронировать",
                                        uz:"Band qilish"
                                    }} />
                                    
                                </Link>
                            </div>
                            <div className='md:grid hidden grid-cols-2 lg:gap-[20px] gap-2 w-full'>
                                {state.img_list.map(item => (
                                    <div key={item.id}>
                                        <img  src={item.img} alt={`${state.title}_image`} className=''/>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> 

                <Footer />
            </div>
        )
    }
}

export {LocationPage}

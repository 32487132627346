import React, { useEffect, useRef} from 'react'
import { Nav } from '../../components/Nav'
import Slider from 'react-slick';
import mouse from "../../assets/icons/mouse.svg"
import { countries } from '../../db/countiesDB';
import  NextArrow  from '../../components/NextArrow';
import PrevArrow from '../../components/PrevArrow';
import { TranslateText } from '../../context/TranslateText';
import { locations } from '../../db/locationDB';
import { Link } from 'react-router-dom';


const Header = () => {
  const sliderRef = useRef(null)
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  // useEffect(() => {
  //   console.log(window.innerWidth)
  // })
  const count = window.innerWidth > 640 ? 3 : 1

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: 1,
    nextArrow: <NextArrow onClick={handleNext} className='slick-next' />,
    prevArrow: <PrevArrow onClick={handlePrev} className='slick-prev' />
  };
  return (
    <div className='max-w-[1440px] mx-auto z-40 flex flex-col relative'>
       
        <Nav />
        <div className='container sm:max-w-[535px] max-w-[293px] mt-[120px] px-[20px]'>
            <h1 className='text-center font-montserrat sm:text-[40px] text-[28px] text-white font-medium'>
              <TranslateText
                dictionary={{
                  en:"Travel the whole world together Cosmos Tour",
                  ru:"Путешествуйте весь мир вместе Cosmos Tour",
                  uz:"Butun dunyoni Cosmos Tour bilan sayr qiling"
                }}
              />
              </h1>

        </div>
        <div className='sm:container mt-[120px] mx-[10px] mb-[40px]'>
        <Slider ref={sliderRef} {...settings} className='text-white capitalize font-ibm header-slider'>
          {
            locations.map((item) => (
              <Link 
                key={item.id} 
                to={"location" + item.slug}
                state={item}
                onClick={() => {
                  window.scrollTo(0,0)
                }}
                className='px-[10px]'>
              <h3 className='text-[28px] font-semibold'>{<TranslateText dictionary={item.city} />}</h3>
              <span className='text-smooth'>{<TranslateText dictionary={item.country} />}</span>
              <div className='relative'>
                <img src={item.h_img} alt={`${item.city}_image`} className='mt-[8px] w-full' />
                <svg className='absolute top-4 right-10 p-[10px] rounded-full cursor-pointer heart' width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  
                    <path id="Vector" d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z" fill={`white`}/>
                
                </svg>
              </div>
            </Link>
            ))
          }
        </Slider>
        </div>
        <div className='container mt-[100px]'>
          {/* <img src={mouse} alt='mouse_image' className='mx-auto'/> */}
        </div>
    </div>
  )
}

export {Header}
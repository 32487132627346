import React from 'react'
import { Footer } from '../../components/footer/footer'
import { TranslateText } from '../../context/TranslateText'

const Contact = () => {
  return (
    <div>
        
    <div className='max-w-[1440px] mx-auto mb-[120px] '>
        <div className='container relative'>
        <span onClick={() => {
            window.location.reload();
        }}
            className='absolute -bottom-[120px] right-0 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" className='bg-active-purple ' width="32" height="32" viewBox="0 0 24 24" fill="none">
                <path d="M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z" fill="white"/>
            </svg>
        </span>
            <div className='mt-[121px] flex lg:flex-row flex-col lg:justify-between justify-start  lg:items-center items-start '>
                <div>
                    <h2 className='font-ibm xl:text-[40px] text-[30px] font-semibold'>
                    <TranslateText dictionary={{
                            en:"",
                            ru:"",
                            uz:"Biz bilan "
                        }} />
                        <span className='text-active-purple '>
                        <TranslateText 
                            dictionary={{
                                en:"Connect",
                                ru:"Связаться",
                                uz:" bog'lanish"
                            }}
                        />
                        </span> <TranslateText dictionary={{
                            en:" with us",
                            ru:" с нами",
                            uz:""
                        }} /> </h2>
                    <p className='font-montserrat text-main xl:text-[21px] text-base'>
                        <TranslateText dictionary={{
                            en:"We are pleased to offer you an exciting journey through time.",
                            ru:"Мы рады предложить вам увлекательное путешествие во времени.",
                            uz:"Biz sizga vaqt bo'ylab qiziqarli sayohatni taklif qilishdan mamnunmiz."
                        }} /></p>
                </div>
                <a href="tel:+998977957222" className='bg-active-purple font-montserrat font-medium text-[16px] capitalize text-white px-[20px] py-[12px] lg:block hidden rounded-full'>связаться</a>
            </div>

            <form className='grid md:grid-cols-2 grid-cols-1 md:mt-[110px] mt-[30px]'>
                <div className='flex flex-col xl:gap-[30px] gap-[10px] xl:pr-[198px] lg:pr-[100px] md:pr-8 pr-0'>

                <div >
                    <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"name", ru:"имя", uz:"ism"}} /></label>
                    <br/>
                    <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                </div>
                <div>
                    <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500">email</label>
                    <br/>
                    <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                </div>
                <div>
                    <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"phone", ru:"телефон", uz:"telefon"}} /></label>
                    <br/>
                    <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                </div>
                </div>
                <div className='xl:pr-[198px] lg:pr-[100px] md:pr-8 pr-0 md:mt-0 mt-2'>
                    <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"comment", ru:"комментарии", uz:"izoh"}} /></label>
                    <br/>
                    <textarea type='text' className='border-b-[1px] border-black focus:outline-none w-full xl:h-[90%] h-[86%]' />
                </div>
                
                <button className='bg-active-purple font-montserrat font-medium lg:text-[16px] text-[14px] capitalize text-white xl:px-[20px] lg:px-[12px] px-[8px] lg:py-[12px] py-[10px] lg:max-w-[440px] md:max-w-[300px] max-w-full rounded-full mt-[56px]'><TranslateText dictionary={{en:"submit for consideration", ru:"представлять на рассмотрение", uz:"ko'rib chiqish uchun taqdim etish"}} /></button>
            </form>
        </div>
    </div>
    <Footer />
    </div>
  )
}

export {Contact}

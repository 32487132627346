import { createSlice } from '@reduxjs/toolkit'
import { data } from 'autoprefixer'
import { act } from 'react-dom/test-utils'

const initialState = {
  value: 1,
  locations:[],
  result: 0
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
        if(state.value > 1){
            state.value -= 1
        }
    },
    locationPrice: (state, action) => {
        state.locations.push(action.payload)
    },
    updateLocationPrice:(state, action) => {
        let {locations} = state
        state.locations = locations.map((item) => 
            item.id === action.payload.id ? action.payload : item
        )
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

export const { increment, decrement, incrementByAmount, locationPrice, updateLocationPrice } = counterSlice.actions

export default counterSlice.reducer

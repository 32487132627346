import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import { LocationPage } from "./pages/LocationPage";
import { ContactPage } from "./pages/ContactPage";
import { About } from "./pages/About/About";
import { Booking } from "./pages/Booking";
import { Order } from "./pages/Order";

function App() {
  return (
    <Routes>
      <Route path="/" element={ <MainPage />} />
      <Route path="/contact" element={ <ContactPage />} />
      <Route path="/about" element={ <About />} />
      <Route path="/booking" element={<Booking />} />
      <Route path="/order" element={<Order />} />
      <Route path="location/*" element={<LocationPage />} />
    </Routes>
  );
}

export default App;

import React, { useRef } from "react";
import Slider from "react-slick";
import NextArrow from "../../components/NextArrow";
import PrevArrow from "../../components/PrevArrow";
import { Link } from "react-router-dom";
import { TranslateText } from "../../context/TranslateText";
import { locations } from "../../db/locationDB";

const Recommended = () => {
  const sliderRef = useRef(null);
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const bl = window.innerWidth > 1024 ? false : true;

  const settings = {
    dots: bl,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow onClick={handleNext} className2="slick-recom-next" />,
    prevArrow: <PrevArrow onClick={handlePrev} className2="slick-recom-prev" />,
  };
  return (
    <div className="max-w-[1440px] max-h-[1080px] overflow-hidden mx-auto">
      <div className="container  mt-[118px] flex md:flex-row flex-col md:justify-between justify-start items-center">
        <div>
          <h2 className="font-ibm md:text-[40px] text-[21px] font-semibold">
            <span className="text-active-purple ">
              <TranslateText
                dictionary={{
                  en: "Recommended ",
                  ru: "Рекомендуемые ",
                  uz: "Tavsiya etilgan ",
                }}
              />
            </span>{" "}
            <TranslateText
              dictionary={{
                en: "tours",
                ru: "туры",
                uz: "sayohatlar",
              }}
            />{" "}
          </h2>
          <p className="font-montserrat text-main md:text-[21px] text-base">
            <TranslateText
              dictionary={{
                en: "An irresistible combination of Western luxury and Eastern culture",
                ru: "Непреодолимое сочетание западной роскоши и восточной культуры",
                uz: "G'arb hashamati va Sharq madaniyatining chidab bo'lmas uyg'unligi",
              }}
            />
          </p>
        </div>
        <a
          href="tel:+998977957222"
          className="bg-active-purple font-montserrat font-medium text-[16px] capitalize md:block hidden text-white px-[20px] py-[12px] rounded-full"
        >
          <TranslateText
            dictionary={{
              en: "get in touch",
              ru: "связаться",
              uz: "aloqa",
            }}
          />
        </a>
      </div>
      <div className="container mx-auto md:mt-24 mt-12 max-h-[600px]">
        <div className="w-full">

        <Slider
          ref={sliderRef}
          {...settings}
          className="capitalize lg:max-w-[75%] w-full ml-auto font-ibm recommen-slider relative py-2 max-h-[590px]"
        >
          {locations.map((item) => (
            <Link
              to={"location" + item.slug}
              key={item.id}
              state={item}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-[10px] relative bg-white flex flex-col justify-between min-h-[520px]"
            >
              <img
                src={item.img}
                alt="misr_image"
                className="w-full rounded-[10px]"
              />
              <div className="px-[20px] pt-[10px] pb-[24px]">
                <h3 className="md:text-[21px] text-base font-medium font-ibm normal-case">
                  {<TranslateText dictionary={item.recom_title} />}{" "}
                </h3>
                <div className="flex mt-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M16.364 15.364L10 21.7279L3.63604 15.364C0.12132 11.8492 0.12132 6.15076 3.63604 2.63604C7.15076 -0.87868 12.8492 -0.87868 16.364 2.63604C19.8787 6.15076 19.8787 11.8492 16.364 15.364ZM10 13C12.2091 13 14 11.2091 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13ZM10 11C8.8954 11 8 10.1046 8 9C8 7.89543 8.8954 7 10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11Z"
                      fill="#7000FF"
                    />
                  </svg>
                  <span className="text-main font-montserrat md:text-[16px] text-[12px] font-normal ml-[6px]">
                    {<TranslateText dictionary={item.location} />}
                  </span>
                </div>
                <p className="text-smooth font-montserrat md:text-[14px] text-[12px] font-normal mt-[17px] leading-normal">
                  {<TranslateText dictionary={item.text} />}
                </p>
                <p className="text-end md:text-base text-[12px] absolute bottom-2 right-4 lowercase">
                  {item.price.toLocaleString("ru-RU")} <TranslateText dictionary={{en:"sum", ru:"сум", uz:"so'm"}} />
                </p>
              </div>
            </Link>
          ))}
        </Slider>
        </div>
      </div>
    </div>
  );
};

export { Recommended };

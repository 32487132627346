import { createContext, useState } from "react";
export const LanguageContext = createContext();
export const LanguageConsumer = LanguageContext.Consumer;

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("ru");

  const updateLang = (newLang) => {
    setLanguage(newLang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        updateLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };

import React, { useState } from 'react'
import { Nav } from '../../components/Nav'

import payme from "../../assets/images/payme.png"
import click from "../../assets/images/click.png"
import { TranslateText } from '../../context/TranslateText'

const Order = () => {
  const [active, setActive] = useState(false)

  const handleActive = () => {
    setActive(true)
  }

  return (
    <div>
        <Nav version={2} />
        <div className='max-w-[1440px] mx-auto mb-16'>
          <div className='container mt-[90px]'>
            <p className='px-[21px] py-[12px]  bg-active-purple text-white'>
              <TranslateText dictionary={{
                en:"Do you have a coupon? Click to enter code",
                ru:"Есть купон? Нажмите, чтобы ввести код",
                uz:"Kuponingiz bormi? Kodni kiritish uchun bosing"
              }} /></p>

            <div className='grid lg:grid-cols-3 grid-cols-1 mt-[32px] lg:gap-[30px] gap-[10px]'>

              <div className='col-span-2'>
                <h2 className='text-main font-ibm lg:text-[38px] sm:text-[28px] text-[21px] font-semibold'>
                  <TranslateText dictionary={{
                    en:"Payment details",
                    ru:"Детали оплаты",
                    uz:"To'lov tafsilotlari"
                  }} /></h2>
                <div className='grid grid-cols-2 gap-[31px]  mt-[42px]'>
                  <div >
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"name", ru:"имя", uz:"ism"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>
                  <div >
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"surname", ru:"фамилия", uz:"familiya"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>
                </div>

                <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"country/region", ru:"страна/регион", uz:"mamlakat/mintaqa"}} /></label>
                      <br/>
                      <select className='border-b-[1px] border-black focus:outline-none w-full'>
                        <option className='checked:bg-active-purple checked:text-white'></option>
                        <option className='checked:bg-active-purple checked:text-white'>Ташкент</option>
                        <option className='checked:bg-active-purple checked:text-white'>Навои</option>
                        <option className='checked:bg-active-purple checked:text-white'>Самарканд</option>
                        <option className='checked:bg-active-purple checked:text-white'>Бухоро</option>
                        <option className='checked:bg-active-purple checked:text-white'>Aндижон </option>
                        <option className='checked:bg-active-purple checked:text-white'>Наманган </option>
                        <option className='checked:bg-active-purple checked:text-white'>Фарғона </option>
                        <option className='checked:bg-active-purple checked:text-white'>Сирдарё </option>
                        <option className='checked:bg-active-purple checked:text-white'>Жиззах </option>
                        <option className='checked:bg-active-purple checked:text-white'>Хоразм </option>
                        <option className='checked:bg-active-purple checked:text-white'>Сурхондарё </option>
                        <option className='checked:bg-active-purple checked:text-white'>Қашқадарё</option>
                        <option className='checked:bg-active-purple checked:text-white'>Қорақалпоғистон Республикаси</option>
                      </select>
                  </div>

                <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"address", ru:"адрес", uz:"manzil"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"locality", ru:"населённый пункт", uz:"aholi punkti"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"region/district", ru:"область/район", uz:"viloyat/tuman"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"postcode", ru:"почтовый индекс", uz:"pochta indeksi"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"phone", ru:"телефон", uz:"telefon"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize after:content-['*'] after:ml-0.5 after:text-red-500"><TranslateText dictionary={{en:"email", ru:"email", uz:"email"}} /></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>

                  <div className="lg:mt-[37px] mt-[30px]">
                      <label className="text-main text-[16px] font-montserrat font-medium capitalize"><TranslateText dictionary={{en:"order note", ru:"примечание к заказу", uz:"buyurtma eslatmasi"}} /> <span className='text-active-purple'>(<TranslateText dictionary={{en:"not necessary", ru:"необязательно", uz:"shart emas"}} />)</span></label>
                      <br/>
                      <input type='text' className='border-b-[1px] border-black focus:outline-none w-full' />
                  </div>
              </div>

              <div className='lg:mb-0 mb-8'>
              <h2 className='text-main font-ibm lg:text-[38px] sm:text-[28px] text-[21px] font-semibold'><TranslateText dictionary={{en:"Your order", ru:"Ваш заказ", uz:"Sizning buyurtmangiz"}} /></h2>

              {/* <div className='flex flex-col p-[24px] shadow-[0_3px_10px_rgb(0,0,0,0.2)] mt-[42px] font-montserrat '>
                <div className='flex border-b-[1px] items-end border-smooth last:border-none pb-[12px] pt-[12px] first:pt-0'>
                  <div className='w-full'>
                    <h2 className='font-semibold capitalize'>тур по дубаю</h2>
                    <span>количество: 2</span>
                  </div>
                  <h3 className='text-end w-full'>40 000 000 сум</h3>
                </div>

                <div className='flex border-b-[1px] items-end border-smooth last:border-none pb-[12px] pt-[12px] first:pt-0'>
                  <div className='w-full'>
                    <h2 className='font-semibold capitalize'>тур по дубаю</h2>
                    <span>количество: 2</span>
                  </div>
                  <h3 className='text-end w-full'>40 000 000 сум</h3>
                </div>

                <div className='flex border-b-[1px] items-end border-smooth last:border-none pb-[12px] pt-[12px] first:pt-0'>
                  <div className='w-full'>
                    <h2 className='font-semibold capitalize'>тур по дубаю</h2>
                    <span>количество: 2</span>
                  </div>
                  <h3 className='text-end w-full'>40 000 000 сум</h3>
                </div>
              </div> */}

              <div className='mt-[26px] font-montserrat capitalize'>
                <div className='flex justify-between items-center'>
                  <h3 className='font-medium'>подытог</h3>
                  <span className='lg:text-[21px] text-base font-medium'>80 000 000 <TranslateText dictionary={{en:"sum", ru:"сум", uz:"so'm"}} /></span>
                </div>

                <div className='flex justify-between items-center'>
                  <div className='font-semibold'>итого</div>
                  <span className='lg:text-[21px] text-base font-semibold'>80 000 000 <TranslateText dictionary={{en:"sum", ru:"сум", uz:"so'm"}} /></span>
                </div>
              </div>

              <div className='flex justify-between mt-[48px]'>
                {/* <div onClick={handleActive} className={`px-[5px] py-[14px] border-[2px] ${active ? 'border-active-purple' : 'border-smooth'} rounded-[5px] flex flex-col items-center max-w-[110px]`}>
                  <img src={payme} alt='payme_image' />
                  <h2 className='text-[12px]'>онлайн оплата</h2>
                </div>

                <div onClick={handleActive} className={`px-[5px] py-[14px] border-[2px] ${active ? 'border-active-purple' : 'border-smooth'} rounded-[5px] flex flex-col items-center max-w-[110px]`}>
                  <img src={click} alt='click_image' />
                  <h2 className='text-[12px]'>онлайн оплата</h2>
                </div> */}

                <div onClick={handleActive} className={`px-[5px] py-[14px] border-[2px] ${active ? 'border-active-purple' : 'border-smooth'} rounded-[5px] flex flex-col items-center max-w-[110px]`}>
                  <h2 className='max-w-[77px] text-center text-[12px]'><TranslateText dictionary={{en:"cash payment", ru:"оплата наличными", uz:"naqd to'lov"}} /></h2>
                </div>
              </div>

              <p className='text-[12px] font-montserrat font-normal mt-[23px]'>
              <TranslateText dictionary={{
                en:"Your personal data will be used to process your order, support your interaction with this website and for other purposes described in our privacy policy.", 
                ru:" Ваши личные данные будут использоваться для обработки вашего заказа, поддержки вашего взаимодействия с этим веб-сайтом и для других целей, описанных в нашей политике конфиденциальности.", 
                uz:"Sizning shaxsiy ma'lumotlaringiz buyurtmangizni qayta ishlash, ushbu veb-sayt bilan o'zaro munosabatlaringizni qo'llab-quvvatlash va maxfiylik siyosatimizda tasvirlangan boshqa maqsadlarda foydalaniladi."
                }} />
             
              </p>

              <button className='mt-[20px] bg-active-purple font-montserrat font-medium text-[16px] capitalize text-white w-full py-[12px] rounded-full'><TranslateText dictionary={{en:"Valide purchase", ru:"подтвердить заказ", uz:"Xaridni tasdiqlang"}} /></button>

              </div>
            </div>
          </div>

        </div>
    </div>
  )
}

export {Order}

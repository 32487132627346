import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nav } from '../../components/Nav'
import dubay from "../../assets/images/dubay.png"
import { decrement, increment } from '../../feature/counter/counterSlice'

const Booking = () => {
    // eslint-disable-next-line no-use-before-define
    const count = useSelector((state) => state.counter.value)
    const location = useSelector((state) => state.counter.locations)
    console.log(location)
    const dispatch = useDispatch()
    
  return (
    <div className='bg-[#F4F4F4] h-full '>
        <Nav version={2} />
        <div className='max-w-[1440px] mx-auto'>
            <div className='container flex gap-[100px] relative'>
                <div className='mt-[90px]'>
                    <div className='w-[830px] rounded-b-lg bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>
                        <h2 className='px-[40px] py-[12px] bg-main text-white'>Вы отложили “Тур по Дубаю” в свою корзину.</h2>
                        <div className='flex px-[40px] pt-[40px] pb-[35px] gap-[38px]'>
                            <img src={dubay} alt="dubay_image" />
                            <div className='w-full  relative'>

                            <h3 className='text-main font-ibm text-[28px] font-semibold'>
                                Тур по Дубаю
                            </h3>
                            <div className='p-[5px] rounded-lg bg-[#F4F4F4] inline-block mt-[42px] mb-[20px]'>
                                <button onClick={() => dispatch(increment())} className='py-[8px] px-[16px] rounded-lg hover:text-active-purple text-[18px] font-black bg-white'>+</button>
                                <span className='mx-[18px] text-[18px]'>{count}</span>
                                <button onClick={() => dispatch(decrement())} className='py-[8px] px-[16px] rounded-lg hover:text-active-purple text-[18px] font-black bg-white'>-</button>
                            </div>
                            <h3 className='font-montserrat text-[21px] font-semibold text-end'>40 000 000 сум</h3>

                            <button className='absolute top-0 right-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" fill="#737373"/>
                                </svg>
                            </button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='h-screen'>
                    <div className='fixed top-1/3'>
                        <h3 className='text-main text-[28px] font-semibold font-montserrat'>Сумма заказов</h3>
                        <div className='relative w-full my-[30px]'>
                            <input type='text' className='w-[330px] focus:outline-none px-[20px] py-[10px] rounded-full border-[1px] border-#B6B6B6' />
                            <button className='absolute right-1 top-[3px] capitalize rounded-full px-[20px] py-[8px] bg-main text-white'>
                                применить 
                            </button>
                        </div>

                        <div className='capitalize font-montserrat font-medium'>
                            <h3 className=' text-main  text-base'>
                                подытог
                            </h3>
                            <span className='text-[21px]'>80 000 000 сум</span>
                        </div>

                        <div className='capitalize font-montserrat font-semibold my-[24px]'>
                            <h3 className=' text-main  text-base'>
                                итого
                            </h3>
                            <span className='text-[21px]'>80 000 000 сум</span>
                        </div>
                        <button className='bg-active-purple font-montserrat font-medium text-[16px] capitalize text-white w-full py-[11px] rounded-full'>
                            забронировать
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export {Booking}

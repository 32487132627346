import React, { useEffect, useRef } from 'react'
import { Nav } from '../../components/Nav'
import { Footer } from '../../components/footer/footer'
import img1 from "../../assets/images/about/img1.png"
import img2 from "../../assets/images/about/img2.png"
import img3 from "../../assets/images/about/img3.png"
import img4 from "../../assets/images/about/img4.png"
import Slider from 'react-slick'
import NextArrow from '../../components/NextArrow'
import PrevArrow from '../../components/PrevArrow'
import { recoms } from '../../db/recomDBb'
import { TranslateText } from '../../context/TranslateText'
import { locations } from '../../db/locationDB'
import { Link } from 'react-router-dom'

const About = () => {
    const sliderRef = useRef(null)
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const count = window.innerWidth > 1024 ? 4 : window.innerWidth > 640 ? 2 : 1

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: 1,
    controlArrows: false,
    nextArrow: <NextArrow onClick={handleNext} className2='slick-about-next' />,
    prevArrow: <PrevArrow onClick={handlePrev} className2='slick-about-prev' />
  };
  return (
    <div>
      <Nav version={2} />
      <header className="bg-about bg-center bg-cover bg-no-repeat w-full sm:h-[730px] h-[500px] flex justify-center relative -z-30 pt-[170px]">
        <div className='bg-smooth w-full h-full absolute top-0 left-0 -z-20 mix-blend-multiply'></div>
        <h1 className="text-white font-ibm lg:text-[51px] md:text-[41px] sm:text-[31px] text-[24px]  font-semibold max-w-[786px] text-center">
          <TranslateText dictionary={{
            en:"Let us create the most memorable adventure for you",
            ru:"Создадим для вас самое запоминающееся приключение",
            uz:"Keling, siz uchun eng unutilmas sarguzashtni yarataylik"
          }}
          />
        </h1>
      </header>

      <div className="max-w-[1440px] mx-auto">
        <div className="container flex xl:flex-row flex-col sm:mt-[65px] mt-[30px] mb-[160px] lg:gap-[90px] md:gap-[60px] gap-[30px]">
          <div>
            <h2 className="text-main font-ibm lg:text-[36px] md:text-[28px] text-[21px] font-semibold">
            <TranslateText dictionary={{
              en:"Discover the world with a travel agency",
              ru:"Откройте для себя мир с туристическим агентством",
              uz:"Sayyohlik agentligi bilan dunyoni kashf eting"
            }}
              />
              {" "}
              <span className="text-active-purple">Cosmos Tour</span>
            </h2>
            <p className="mt-[20px] text-main font-montserrat text-[16px]">
            <TranslateText dictionary={{
              en:`Planning a trip can be a daunting task, but...
              with the right travel agency this process will be smooth,
              enjoyable and ensures that you have an unforgettable experience
              for life.`,
              ru:`Планирование поездки может оказаться непростой задачей, но с
              правильным туристическим агентством этот процесс станет гладким,
              приятным и гарантирует, что вы получите незабываемые впечатления
              на всю жизнь.`,
              uz:`Sayohatni rejalashtirish juda qiyin vazifa bo'lishi mumkin, ammo...
              to'g'ri sayyohlik agentligi bilan bu jarayon silliq bo'ladi,
              yoqimli va unutilmas tajribaga ega bo'lishingizni ta'minlaydi
              hayot uchun.`
            }}
              />
              
              <br />
              <br />
              <TranslateText dictionary={{
              en:`Travel agency Cosmos tour strives to create
              extraordinary adventures that will allow you to explore the world,
              immerse yourself in diverse cultures and leave unforgettable memories
              memories`,
              ru:`Туристическое агентство Cosmos tour стремится создавать
              необыкновенные приключения, которые позволят вам исследовать мир,
              погрузиться в разнообразные культуры и оставить незабываемые
              воспоминания`,
              uz:`Cosmos tour sayyohlik agentligi yaratishga intiladi
              dunyoni kashf qilish imkonini beradigan g'ayrioddiy sarguzashtlar,
              O'zingizni turli madaniyatlarga botiring va unutilmas xotiralar qoldiring
              xotiralar`
            }}
              />
              
            </p>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-[20px] xl:gap-y-[30px] md:gap-y-[100px] sm:gap-y-[150px] gap-y-[120px]">
            <div className="relative">
              <h2 className="font-ibm text-[21px] text-main font-medium">
              <TranslateText dictionary={{
              en:`Unforgettable impressions`,
              ru:`Незабываемые впечатления`,
              uz:`Unutilmas taassurotlar`
            }}
              />
                
              </h2>
              <p className="font-montserrat xl:text-[16px] text-[14px] mt-[16px] pr-6">
              <TranslateText dictionary={{
              en:`Our itineraries are designed to immerse you in the most...
              the heart of every destination, allowing you to experience
              local communities, traditions and authentic experiences.`,
              ru:`Наши маршруты разработаны так, чтобы погрузить вас в самое
              сердце каждого пункта назначения, позволяя вам познакомиться с
              местными сообществами, традициями и подлинным опытом.`,
              uz:`Bizning marshrutlarimiz sizni eng ko'p qiziqtirish uchun mo'ljallangan ...
              har bir maqsadning yuragi, bu sizga tajriba qilish imkonini beradi
              mahalliy jamoalar, an'analar va haqiqiy tajribalar.`
            }}
              />
                
              </p>
              <img
                src={img1}
                alt="about_image"
                className="absolute xl:right-4 lg:right-8 right-10 xl:bottom-4 -bottom-20 -z-10 xl:w-[157px] xl:h-[157px] w-[137px] h-[137px]"
              />
            </div>

            <div className="relative">
              <h2 className="font-ibm text-[21px] text-main font-medium">
              <TranslateText dictionary={{
              en:`Best guides`,
              ru:`Лучшие гиды`,
              uz:`Eng yaxshi qo'llanmalar`
            }}
              />
                
              </h2>
              <p className="font-montserrat xl:text-[16px] text-[14px] mt-[16px] pr-6">
              <TranslateText dictionary={{
              en:`Our fun and experienced guides can make a great
              vacation into an unforgettable, inspiring, and sometimes truly
              life changing adventure`,
              ru:`Наши увлекательные и опытные гиды могут превратить отличный
              отдых в незабываемое, вдохновляющее, а иногда и по-настоящему
              изменяющее жизнь приключение`,
              uz:`Bizning qiziqarli va tajribali gidlarimiz ajoyib ishlarni amalga oshirishi mumkin
              unutilmas, ilhomlantiruvchi va ba'zan chinakam ta'tilga aylanadi
              hayotni o'zgartiruvchi sarguzasht`
            }}
              />
                
              </p>
              <img
                src={img2}
                alt="about_image"
                className="absolute xl:right-4 lg:right-8 right-10 xl:bottom-4 lg:-bottom-20 -bottom-28  -z-10 xl:w-[157px] xl:h-[157px] w-[137px] h-[137px]"
              />
            </div>

            <div className="relative">
              <h2 className="font-ibm text-[21px] text-main font-medium">
              <TranslateText dictionary={{
              en:`Unrivaled convenience and simplicity`,
              ru:`Непревзойденное удобство и простота`,
              uz:`Raqobatsiz qulaylik va soddalik`
            }}
              />
                
              </h2>
              <p className="font-montserrat xl:text-[16px] text-[14px] mt-[16px] pr-6">
              <TranslateText dictionary={{
              en:`From professionally planned routes and organization
              transport to excursions and multilingual tour directors - each
              the detail is carefully thought out, offering you convenience and
              the peace of mind that comes from traveling with a reliable agency.
              Sit back, relax and let the Cosmos tour
              take care of the details while you create unforgettable
              memories.`,
              ru:`От профессионально спланированных маршрутов и организации
              транспорта до экскурсий и многоязычных тур-директоров — каждая
              деталь тщательно продумана, предлагая вам удобство и
              спокойствие, которые дает путешествие с надежным агентством.
              Устройтесь поудобнее, расслабьтесь и позвольте Cosmos tour
              позаботиться о деталях, пока вы создаете незабываемые
              воспоминания.`,
              uz:`Professional tarzda rejalashtirilgan marshrutlar va tashkilotdan
              ekskursiyalar va ko'p tilli tur direktorlariga transport - har biri
              tafsilot puxta o'ylangan bo'lib, sizga qulaylik va
              ishonchli agentlik bilan sayohat qilishdan kelib chiqadigan xotirjamlik.
              Orqaga o'tiring, dam oling va Kosmosga sayohat qiling
              unutilmas narsalarni yaratishda tafsilotlarga e'tibor bering
              xotiralar.`
            }}
              />
                
              </p>
              <img
                src={img3}
                alt="about_image"
                className="absolute xl:right-4 lg:right-8 right-10 xl:bottom-2 -bottom-24 -z-10 xl:w-[157px] xl:h-[157px] w-[137px] h-[137px]"
              />
            </div>

            <div className="relative">
              <h2 className="font-ibm text-[21px] text-main font-medium">
              <TranslateText dictionary={{
              en:`Uncompromising quality and value`,
              ru:` Бескомпромиссное качество и ценность`,
              uz:`Murosasiz sifat va qiymat`
            }}
              />
               
              </h2>
              <p className="font-montserrat xl:text-[16px] text-[14px] mt-[16px] pr-6">
              <TranslateText dictionary={{
              en:`Our strong relationships with local suppliers and partners
              allow us to provide excellent prices without compromising
              quality, ensuring you get excellent value for your
              investments.`,
              ru:`Наши прочные отношения с местными поставщиками и партнерами
              позволяют нам обеспечивать отличные цены без ущерба для
              качества, гарантируя, что вы получите отличную отдачу от своих
              инвестиций.`,
              uz:`Mahalliy yetkazib beruvchilar va hamkorlar bilan mustahkam aloqalarimiz
              bizga hech qanday zarar etkazmasdan mukammal narxlarni ta'minlashga imkon bering
              sifat, o'zingiz uchun ajoyib qiymatga ega bo'lishingizni ta'minlaydi
              investitsiyalar.`
            }}
              />
                
              </p>
              <img
                src={img4}
                alt="about_image"
                className="absolute xl:right-4 lg:right-8 right-10 xl:bottom-24 lg:-bottom-4 -bottom-24 -z-10 xl:w-[157px] xl:h-[157px] w-[137px] h-[137px]"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#F4F4F4]">
        <div className="max-w-[1440px] mx-auto">
              <div className='container pt-[30px]'>
                <h2 className="text-main font-ibm lg:text-[36px] sm:text-[28px] text-[21px] font-semibold max-w-[450px]">
                      <TranslateText dictionary={{
                        en:" ",
                        ru:"",
                        uz:"Bizning sayohatlarimizni"
                      }} />
                    <span className="text-active-purple">
                      <TranslateText dictionary={{
                        en:"Check out",
                        ru:"Ознакомьтесь",
                        uz:" ko'rib chiqing"
                      }} /></span>
                      <TranslateText dictionary={{
                        en:" our tours",
                        ru:" с нашими турами",
                        uz:""
                      }} />
                       
                </h2>
              </div>
                <div className='sm:container pb-[5px] '>
                <Slider
                    ref={sliderRef}
                    {...settings}
                    className="capitalize ml-auto font-ibm relative py-2 mt-[40px] mb-[70px] about-slider h-[550px] sm:px-0 px-[10px]"
                >
                    {locations.map((item) => (
                    <Link
                        to={"/location" + item.slug}
                        state={item}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        key={item.id}
                        className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] relative bg-white flex flex-col justify-between min-h-[520px]"
                    >
                        <img src={item.img} alt="misr_image" className="w-full" />
                        <div className="px-[20px] pt-[10px] pb-[24px">
                        <h3 className="text-[21px] font-medium font-ibm capitalize">
                            {<TranslateText dictionary={item.recom_title} />}
                        </h3>
                        <div className="flex mt-[10px]">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            >
                            <path
                                d="M16.364 15.364L10 21.7279L3.63604 15.364C0.12132 11.8492 0.12132 6.15076 3.63604 2.63604C7.15076 -0.87868 12.8492 -0.87868 16.364 2.63604C19.8787 6.15076 19.8787 11.8492 16.364 15.364ZM10 13C12.2091 13 14 11.2091 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13ZM10 11C8.8954 11 8 10.1046 8 9C8 7.89543 8.8954 7 10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11Z"
                                fill="#7000FF"
                            />
                            </svg>
                            <span className="text-main font-montserrat text-[16px] font-normal ml-[6px]">
                            {<TranslateText dictionary={item.location} />}
                            </span>
                        </div>
                        <p className="text-smooth font-montserrat text-[14px] font-normal mt-[17px] leading-normal">
                            {<TranslateText dictionary={item.text} />}
                        </p>
                        <p className="text-end absolute bottom-1 right-4 cursor-pointer hover:text-active-purple transition-all lowercase duration-700 ease-in-out">
                            {item.price.toLocaleString("ru-RU")} <TranslateText dictionary={{en:" sum", ru:" сум", uz:" so'm"}} />
                        </p>
                        </div>
                    </Link>
                    ))}
                </Slider>
            </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export {About}

import dubay1 from "../assets/images/location/dubay/dubay1.png"
import dubay2 from "../assets/images/location/dubay/dubay2.png"
import dubay3 from "../assets/images/location/dubay/dubay3.png"
import dubay4 from "../assets/images/location/dubay/dubay4.png"

import makka1 from "../assets/images/location/umra/makka1.png"
import makka2 from "../assets/images/location/umra/makka2.png"
import makka3 from "../assets/images/location/umra/makka3.png"
import makka4 from "../assets/images/location/umra/makka4.png"

import turkiya1 from "../assets/images/location/turkiya/turkiya1.png"
import turkiya2 from "../assets/images/location/turkiya/turkiya2.png"
import turkiya3 from "../assets/images/location/turkiya/turkiya3.png"
import turkiya4 from "../assets/images/location/turkiya/turkiya4.png"

import misr1 from "../assets/images/location/misr/Rectangle 25.png"
import misr2 from "../assets/images/location/misr/Rectangle 26.png"
import misr3 from "../assets/images/location/misr/Rectangle 27.png"
import misr4 from "../assets/images/location/misr/Rectangle 28.png"

import boku1 from "../assets/images/location/ozarbayjon/Rectangle 25.png"
import boku2 from "../assets/images/location/ozarbayjon/Rectangle 26.png"
import boku3 from "../assets/images/location/ozarbayjon/Rectangle 27.png"
import boku4 from "../assets/images/location/ozarbayjon/Rectangle 28.png"

import uzbek1 from "../assets/images/location/uzbekistan/Rectangle 25.png"
import uzbek2 from "../assets/images/location/uzbekistan/Rectangle 26.png"
import uzbek3 from "../assets/images/location/uzbekistan/Rectangle 27.png"
import uzbek4 from "../assets/images/location/uzbekistan/Rectangle 28.png"

import tailand1 from "../assets/images/location/tailand/Rectangle 25.png"
import tailand2 from "../assets/images/location/tailand/Rectangle 26.png"
import tailand3 from "../assets/images/location/tailand/Rectangle 27.png"
import tailand4 from "../assets/images/location/tailand/Rectangle 28.png"
import tailand from "../assets/images/location/tailand/Rectangle 8.png"


import gruziya1 from "../assets/images/location/gruziya/Rectangle 25.png"
import gruziya2 from "../assets/images/location/gruziya/Rectangle 26.png"
import gruziya3 from "../assets/images/location/gruziya/Rectangle 27.png"
import gruziya4 from "../assets/images/location/gruziya/Rectangle 28.png"
import gruziya from "../assets/images/location/gruziya/Rectangle 8.png"


import dubay from "../assets/images/recom/dubay.png"
import misr from "../assets/images/recom/misr.png"
import baku from "../assets/images/recom/baku.png"
import turkiya from "../assets/images/recom/turkiya.png"
import makka from "../assets/images/recom/makka.png"
import toshkent from "../assets/images/recom/toshkent.png"

import hmisr from "../assets/images/mainPage/misr.png"
import hdubay from "../assets/images/mainPage/dubay.png"
import hboku from "../assets/images/mainPage/baku.png"
import hturkiya from "../assets/images/mainPage/turkiya.png"
import hmakka from "../assets/images/mainPage/makka.png"
import htoshkent from "../assets/images/mainPage/toshkent.png"


const locations = [
    {
        id:1,
        title:{
            en:"Umrah pilgrimage",
            ru:"Умра паломничество ",
            uz:"Umra ziyorati"
        },
        link_title:{
            en:"Umrah pilgrimage",
            ru:"Умра паломничество ",
            uz:"Umra ziyorati"
        },
        sup_text:{
            en:"Umrah, the lesser pilgrimage to the holy cities of Mecca and Medina in Saudi Arabia, holds immense spiritual significance for millions of Muslims around the world. Embarking on an Umrah tour offers a unique opportunity for spiritual reflection, connection with fellow believers, and a chance to experience the sacred sites of Islam. In this article, we will explore the compelling reasons why you should choose Umrah for your spiritual journey.",
            ru:"Умра, меньшее паломничество в священные города Мекку и Медину в Саудовской Аравии, имеет огромное духовное значение для миллионов мусульман во всем мире. Поездка в умру дает уникальную возможность для духовного размышления, общения с единоверцами и возможность познакомиться со священными местами ислама. В этой статье мы рассмотрим веские причины, по которым вам следует выбрать умру для своего духовного пути.",
            uz:"Yevropa va Osiyo oʻrtasidagi koʻprik sifatida Turkiya Sharq va Gʻarb taʼsirini oʻziga xos tarzda uygʻunlashtirib, madaniyatlarning ajoyib uygʻunligini yaratadi. Mamlakatning jonli va kosmopolit poytaxti bo'lgan Istanbul Vizantiya, Rim va Usmonli madaniyatlari ta'sirida boy tarixga ega bo'lib, bu uyg'unlikni mukammal tarzda ifodalaydi. Istanbulning hayratlanarli masjidlar, jo'shqin bozorlar va zamonaviy san'at galereyalari bilan bezatilgan gavjum ko'chalarini o'rganing. Turkiyaning madaniy birlashuvi har bir sayohatchiga boyituvchi va hayratlanarli tajriba taqdim etadi."
        },    
        subtitle:{
            en:'Proximity to <span style="color:#7000FF">holy</span> places',
            ru:'Близость к <span style="color:#7000FF">святым</span> местам',
            uz:'<span style="color:#7000FF">Muqaddas</span> joylarga yaqinlik'
        },
        sub_text:{
            en:"Embarking on an Umrah tour grants you unparalleled access to the holy cities of Mecca and Medina, which hold immense historical and spiritual significance in Islam. The opportunity to pray in Masjid al-Haram, home to the Kaaba, is a profound experience that fills the heart with awe and humility. Visiting the Prophet's Mosque in Medina, where the final resting place of the Prophet Muhammad (peace be upon him) is located, offers a chance to pay respects and connect with the Prophet's noble teachings.",
            ru:'Отправляясь в тур Умра, вы получаете беспрецедентный доступ к священным городам Мекке и Медине, которые имеют огромное историческое и духовное значение в исламе. Возможность помолиться в Масджид аль-Харам, где находится Кааба, — это глубокий опыт, наполняющий сердце трепетом и смирением. Посещение Мечети Пророка в Медине, где находится место последнего упокоения Пророка Мухаммада (мир ему), дает возможность выразить почтение и приобщиться к благородному учению Пророка.',
            uz:"Umra safariga chiqish sizga islomda ulkan tarixiy va ma’naviy ahamiyatga ega bo‘lgan muqaddas Makka va Madina shaharlariga tengsiz kirish imkonini beradi. Ka’ba maskani bo‘lmish Masjidul Haramda namoz o‘qish imkoniyati qalbni hayrat va tavozega to‘ldiradigan chuqur tajribadir. Payg'ambarimiz Muhammad sollallohu alayhi vasallamning so'nggi dam olish maskani joylashgan Madinadagi Payg'ambar masjidini ziyorat qilish ehtirom ko'rsatish va Payg'ambarning olijanob o'gitlari bilan bog'lanish imkonini beradi."
        },
        img_list:[
            {
                id:"11",
                img:makka1
            },
            {
                id:"12",
                img:makka2
            },
            {
                id:"13",
                img:makka3
            },
            {
                id:"14",
                img:makka4
            },
        ],
        price:15000000,
        recom_title:{
            en:"Umrah pilgrimage",
            ru:"Умра паломничество ",
            uz:"Umra ziyorati"
        },
        location:{
            en:"Mecca, Saudi Arabia",
            ru:"мекка, саудовская аравия",
            uz:"Makka, Saudiya Arabistoni"
        },
        text:{
            en:"One of the significant pilgrimages that Muslims can undertake in Saudi Arabia at any time of the year is Umrah. This pilgrimage is part of the larger annual Hajj pilgrimage.",
            ru:"Одним из значительных паломничеств, которые мусульмане могут совершать в Саудовской Аравии в любое время года, является Умра. Это паломничество является частью большого ежегодного паломничества Хадж. ",
            uz:"Yilning istalgan vaqtida Saudiya Arabistonida musulmonlar ziyorat qilishlari mumkin bo'lgan muhim ziyoratlardan biri bu Umradir. Bu ziyorat yillik katta haj ziyoratining bir qismidir."
        },
        img:makka,

        country:{
            en:"Saudi Arabia",
            ru:"саудовская аравия",
            uz:"Saudiya Arabistoni"
        },
        city:{
            en:"Mecca",
            ru:"мекка",
            uz:"makka"
        },
        h_img:hmakka,

        slug:"/makka"
    },
    {
        id:2,
        title:{
            en:"Dubai",
            ru:"Дубай",
            uz:"Dubay"
        },
        link_title:{
            en:"tour of dubai",
            ru:"тур по дубаю",
            uz:"dubayga sayohat"
        },
        sup_text:{
            en:"Dubai, a city known for its towering skyscrapers, luxury resorts and vibrant culture, is an increasingly popular destination for travelers from all over the world.",
            ru:"Дубай, город, известный своими высокими небоскребами, роскошными курортами и яркой культурой, становится все более популярным местом для путешественников со всего мира.",
            uz:"Dubay o'zining baland osmono'par binolari, hashamatli kurortlari va jonli madaniyati bilan mashhur bo'lib, butun dunyo bo'ylab sayohatchilar uchun tobora ommalashib bormoqda."
        },
        subtitle: {
            en:'Luxury <span style="color:#7000FF">resorts</span> and unforgettable <span style="color:#7000FF">hospitality</span>',
            ru:'Роскошные <span style="color:#7000FF">курорты</span> и незабываемое <span style="color:#7000FF">гостеприимство</span>',
            uz:`Hashamatli <span style="color:#7000FF">dam olish maskanlari</span> va unutilmas <span style="color:#7000FF">mehmondo'stlik</span`
        },
        sub_text:{
            en:`Dubai is renowned for its exceptional hospitality and luxurious offerings. The city boasts a variety of upscale resorts, each designed to provide a luxurious and unforgettable experience.
            From luxurious accommodations to world-class amenities and services, Dubai ensures that every aspect of your stay is nothing short of extraordinary. Experience the warmth and hospitality of the Emirates that will leave a lasting impression on your journey.`,
            ru:'Дубай славится своим исключительным гостеприимством и роскошными предложениями. Город может похвастаться множеством высококлассных курортов, каждый из которых спроектирован так, чтобы обеспечить роскошный и незабываемый отдых. ' +
        'От роскошных номеров до удобств и услуг мирового класса — Дубай гарантирует, что каждый аспект вашего пребывания будет не чем иным, как экстраординарным. Почувствуйте теплое и гостеприимство Эмиратов, которое оставит неизгладимое впечатление в вашем путешествии.',
            uz:`Dubay o'zining ajoyib mehmondo'stligi va hashamatli takliflari bilan mashhur. Shahar turli xil yuqori darajadagi kurortlarga ega, ularning har biri hashamatli va unutilmas tajribani taqdim etish uchun mo'ljallangan.
            Hashamatli turar joydan tortib, jahon darajasidagi qulayliklar va xizmatlargacha, Dubay turar joyingizning har bir jihati g'ayrioddiy bo'lishini ta'minlaydi. Sayohatingizda unutilmas taassurot qoldiradigan Amirliklar iliqligi va mehmondo'stligini his qiling.`
        },
        img_list:[
            {
                id:"11",
                img:dubay1
            },
            {
                id:"12",
                img:dubay2
            },
            {
                id:"13",
                img:dubay3
            },
            {
                id:"14",
                img:dubay4
            },
        ],
        price:6500000,
        recom_title:{
            en:"Tour of dubai",
            ru:"Tур по дубаю",
            uz:"Dubayga sayohat"
        },
        location:{
            en:"Dubai, UAE",
            ru:"Дубай, ОАЭ",
            uz:"Dubay, BAA",
        },
        text:{
            en:"Land of the Sheikhs, where only the biggest is big enough: Welcome to Dubai! Here you'll find the tallest skyscrapers and finger-licking attractions alongside tons of tradition.",
            ru:"Земля шейхов, где только самый большой достаточно большой: Добро пожаловать в Дубай! Здесь вы найдете самые высокие небоскребы и пальчики оближешь достопримечательности рядом с тоннами традиций.",
            uz:"Shayxlar yurti, bu yerda faqat eng kattasi yetarlicha katta: Dubayga xush kelibsiz! Bu yerda siz tonnalab an'analar qatorida eng baland osmono'par binolar va barmoqlarni yalab ko'radigan diqqatga sazovor joylarni topasiz."
        },
        img:dubay,
        country:{
            en:"UAE",
            ru:"ОАЭ",
            uz:"BAA",
        },
        city:{
            en:"dubai",
            ru:"дубай",
            uz:"dubay"
        },
        h_img:hdubay,
        slug:"/dubay"
    },
    {
        id:4,
        title:{
            en:"Turkey",
            ru:"Турция",
            uz:"Turkiya"
        },
        link_title:{
            en:"tour of Turkey",
            ru:"тур по Турция",
            uz:"Turkiyaga sayohat"
        },
        sup_text:{
            en:"As a bridge between Europe and Asia, Turkey uniquely blends Eastern and Western influences, creating a fascinating fusion of cultures. Istanbul, the country's vibrant and cosmopolitan metropolis, perfectly epitomizes this blend, boasting a rich history influenced by Byzantine, Roman, and Ottoman cultures. Explore the bustling streets of Istanbul, dotted with awe-inspiring mosques, vibrant bazaars, and contemporary art galleries. Turkey's cultural amalgamation offers an enriching and immersive experience for every traveler.",
            ru:"Являясь мостом между Европой и Азией, Турция уникальным образом сочетает в себе влияние Востока и Запада, создавая увлекательное слияние культур. Стамбул, яркий и космополитичный мегаполис страны, прекрасно олицетворяет это сочетание, имея богатую историю под влиянием византийской, римской и османской культур. Исследуйте шумные улицы Стамбула, усеянные впечатляющими мечетями, яркими базарами и галереями современного искусства. Культурное объединение Турции предлагает обогащающий и захватывающий опыт для каждого путешественника.",
            uz:"Yevropa va Osiyo oʻrtasidagi koʻprik sifatida Turkiya Sharq va Gʻarb taʼsirini oʻziga xos tarzda uygʻunlashtirib, madaniyatlarning ajoyib uygʻunligini yaratadi. Mamlakatning jonli va kosmopolit poytaxti bo'lgan Istanbul Vizantiya, Rim va Usmonli madaniyatlari ta'sirida boy tarixga ega bo'lib, bu uyg'unlikni mukammal tarzda ifodalaydi. Istanbulning hayratlanarli masjidlar, jo'shqin bozorlar va zamonaviy san'at galereyalari bilan bezatilgan gavjum ko'chalarini o'rganing. Turkiyaning madaniy birlashuvi har bir sayohatchiga boyituvchi va hayratlanarli tajriba taqdim etadi."
        },
        subtitle:{
            en:'<span style="color:#7000FF">Historical and cultural</span> wonders',
            ru:'<span style="color:#7000FF">Исторические и культурные</span> чудеса',
            uz:'<span style="color:#7000FF">Tarixiy va madaniy</span> ajoyibotlar'
        },
        sub_text:{
            en:"Turkey is a treasure trove of historical and cultural wonders, boasting numerous UNESCO World Heritage sites. Explore the ancient city of Ephesus, admire the majesty of the Hagia Sophia and Topkapi Palace in Istanbul, and wander through the fabulous landscapes of Cappadocia. From the classic ruins of Troy to the stunning Roman ruins of Pamukkale, Turkey offers a fascinating journey through centuries of history and diverse civilizations.",
            ru:'Турция – это сокровищница исторических и культурных чудес, которая может похвастаться многочисленными объектами Всемирного наследия ЮНЕСКО. Исследуйте древний город Эфес, полюбуйтесь величием собора Святой Софии и дворца Топкапы в Стамбуле и побродите по сказочным пейзажам Каппадокии. От классических руин Трои до потрясающих римских руин в Памуккале – Турция предлагает захватывающее путешествие по многовековой истории и разнообразным цивилизациям. ',
            uz:"Turkiya tarixiy va madaniy mo''jizalar xazinasi bo'lib, ko'plab YuNESKOning Jahon merosi ob'ektlari bilan faxrlanadi. Qadimiy Efes shahrini o'rganing, Istanbuldagi Ayasofya va Topkapi saroyining ulug'vorligiga qoyil qoling va Kapadokiyaning ajoyib manzaralari bo'ylab sayr qiling. Troyaning klassik xarobalaridan tortib Pamukkalening hayratlanarli Rim xarobalarigacha Turkiya ko'p asrlik tarix va turli tsivilizatsiyalar bo'ylab ajoyib sayohatni taklif etadi."
        },
            img_list:[
            {
                id:"11",
                img:turkiya1
            },
            {
                id:"12",
                img:turkiya2
            },
            {
                id:"13",
                img:turkiya3
            },
            {
                id:"14",
                img:turkiya4
            },
        ],
        price:13000000,
        recom_title:{
            en:"Tour of Turkey",
            ru:"Tур по Турция",
            uz:"Turkiyaga sayohat"
        },
        location:{
            en:"Istanbul, Türkiye",
            ru:"Стамбул, Турция",
            uz:"Istanbul, Turkiya"
        },
        text:{
            en:"Türkiye is amazingly multifaceted. Leave the coast behind and truly experience the country. You are sure to have a spectacular trip.",
            ru:"Турция удивительно многогранна. Оставьте побережье позади и по-настоящему испытайте страну. У вас, несомненно, будет впечатляющая поездка.",
            uz:"Turkiya hayratlanarli darajada ko'p qirrali. Sohilni ortda qoldiring va haqiqatan ham mamlakatni his eting. Sizni ajoyib sayohatga ega bo'lishingiz aniq."
        },
        img:turkiya,
        country:{
            en:"Türkiye",
            ru:"турция",
            uz:"Turkiya"
        },
        city:{
            en:"Istanbul",
            ru:"стамбул",
            uz:"Istanbul",
        },
        h_img:hturkiya,
        slug:"/turkiya"
    },
    {
        id:5,
        title:{
            en:"Egypt",
            ru:"Египет",
            uz:"Misr"
        },
        link_title:{
            en:"tour of Egypt",
            ru:"тур по Египет",
            uz:"Misrga sayohati"
        },
        sup_text:{
            en:"Egypt, the land of ancient wonders and timeless treasures, entices travelers with its awe-inspiring monuments, mystical landscapes, and fascinating history. From the Great Pyramids and the iconic Sphinx to the temples of Luxor and the serene Nile River, Egypt offers a captivating travel experience like no other.",
            ru:"Египет, земля древних чудес и вечных сокровищ, манит путешественников своими впечатляющими памятниками, мистическими пейзажами и увлекательной историей. От Великих пирамид и знаменитого Сфинкса до храмов Луксора и безмятежной реки Нил — Египет предлагает захватывающие впечатления от путешествий, как никто другой. ",
            uz:"Qadimiy mo’jizalar va abadiy xazinalar mamlakati bo'lgan Misr o'zining hayratlanarli obidalari, mistik manzaralari va maftunkor tarixi bilan sayohatchilarni o'ziga jalb qiladi. Buyuk Piramidalar va ramziy Sfenksdan tortib, Luksor ibodatxonalari va sokin Nil daryosigacha Misr boshqa hech kimga o'xshamaydigan jozibali sayohat tajribasini taklif etadi."
        },
        subtitle:{
            en:'Ancient <span style="color:#7000FF">miracles</span>',
            ru:'Древние <span style="color:#7000FF">чудеса</span>',
            uz:`Qadimgi <span style="color:#7000FF">mo'jizalar</span>`
        },
        sub_text:{
            en:"Egypt is home to some of the world's most iconic ancient wonders, including the Great Pyramids of Giza, the Sphinx, and the Valley of the Kings. These ancient marvels are a testament to the ingenuity and architectural brilliance of the ancient Egyptians. Standing in the shadow of the pyramids or exploring the ornate tombs of pharaohs allows you to connect with a civilization that dates back thousands of years and witness their extraordinary achievements.",
            ru:'Египет является домом для некоторых из самых знаковых древних чудес мира, в том числе Великих пирамид Гизы, Сфинкса и Долины царей. Эти древние чудеса являются свидетельством изобретательности и архитектурного мастерства древних египтян. Стоя в тени пирамид или исследуя богато украшенные гробницы фараонов, вы сможете прикоснуться к цивилизации, насчитывающей тысячи лет, и стать свидетелем ее выдающихся достижений.',
            uz:"Misrda dunyodagi eng ajoyib qadimiy mo''jizalar, jumladan Gizaning Buyuk Piramidalari, Sfenks va Shohlar vodiysi joylashgan. Ushbu qadimiy mo''jizalar qadimgi misrliklarning zukkoligi va me'moriy yorqinligidan dalolat beradi. Piramidalar soyasida turish yoki fir'avnlarning bezakli qabrlarini o'rganish sizga ming yillar oldin mavjud bo'lgan tsivilizatsiya bilan bog'lanish va ularning g'ayrioddiy yutuqlariga guvoh bo'lish imkonini beradi."
        },
        img_list:[
            {
                id:"11",
                img:misr1
            },
            {
                id:"12",
                img:misr2
            },
            {
                id:"13",
                img:misr3
            },
            {
                id:"14",
                img:misr4
            },
        ],
        price:13400000,
        recom_title:{
            en:"Tour of Egypt",
            ru:"Tур по Египет",
            uz:"Misrga sayohati"
        },
        location:{
            en:"Cairo, Egypt",
            ru:"каир, египет",
            uz:"Qohira, Misr"
        },
        text:{
            en:"Legendary ancient pyramids, endless golden sands, atmospheric local souks (markets) and sunny winter holidays make Egypt the perfect travel destination.",
            ru:"Легендарные древние пирамиды, бесконечные золотые пески, атмосферные местные базары (рынки) и солнечные зимние каникулы делают Египет идеальным местом для путешествий.",
            uz:"Afsonaviy qadimiy piramidalar, cheksiz oltin qumlar, atmosferadagi mahalliy bozorlar (bozorlar) va quyoshli qishki ta'tillar Misrni eng zo'r sayohat joyiga aylantiradi."
        },
        img:misr,
        country:{
            en:"Egypt",
            ru:"египет",
            uz:"Misr"
        },
        city:{
            en:"Cairo",
            ru:"каир",
            uz:"Qohira"
        },
        h_img:hmisr,
        slug:"/misr"
    },
    {
        id:6,
        title:{
            en:"Azerbaijan",
            ru:"Азербайджан",
            uz:"Ozarbayjon"
        },
        link_title:{
            en:"tour of Azerbaijan",
            ru:"тур по Азербайджан",
            uz:"Ozarbayjonga sayohati"
        },    
        sup_text:{
            en:"Nestled at the crossroads of Europe and Asia, Azerbaijan beckons travelers with its rich history, stunning landscapes, and vibrant culture. With a perfect blend of ancient heritage, modern architecture, and warm hospitality, Azerbaijan offers a unique and immersive travel experience.",
            ru:"Расположенный на перекрестке Европы и Азии, Азербайджан манит путешественников своей богатой историей, потрясающими пейзажами и яркой культурой. Благодаря идеальному сочетанию древнего наследия, современной архитектуры и теплого гостеприимства, Азербайджан предлагает уникальные и захватывающие впечатления от путешествий.",
            uz:"Yevropa va Osiyo chorrahasida joylashgan Ozarbayjon o'zining boy tarixi, ajoyib manzaralari va jo'shqin madaniyati bilan sayohatchilarni o'ziga jalb qiladi. Qadimiy meros, zamonaviy arxitektura va samimiy mehmondo'stlikning mukammal uyg'unligi bilan Ozarbayjon noyob va hayratlanarli sayohat tajribasini taklif etadi."
        },
        subtitle:{
            en:'Modern <span style="color:#7000FF">architecture</span> and <span style="color:#7000FF">urban elegance</span>',
            ru:'Современная <span style="color:#7000FF">архитектура</span> и <span style="color:#7000FF">городская элегантность</span>',
            uz:'Zamonaviy <span style="color:#7000FF">arxitektura</span> va <span style="color:#7000FF">shahar nafisligi</span>',
        },
        sub_text:{
            en:"With its vibrant capital city, Baku, Azerbaijan seamlessly blends modern architecture with its historical charm. The iconic Flame Towers, Heydar Aliyev Center, and the recently constructed Baku Olympic Stadium are architectural masterpieces that showcase the country's contemporary vision. Witness the stunning contrast of modern skyscrapers against the backdrop of the Caspian Sea, and stroll along the waterfront promenade, known as the Baku Boulevard, for a taste of the city's urban elegance.",
            ru:'Азербайджан со своей оживленной столицей Баку органично сочетает современную архитектуру с историческим очарованием. Знаменитые Пламенные башни, Центр Гейдара Алиева и недавно построенный Бакинский олимпийский стадион — это архитектурные шедевры, демонстрирующие современное видение страны.  Станьте свидетелем потрясающего контраста современных небоскребов на фоне Каспийского моря и прогуляйтесь по набережной, известной как Бакинский бульвар, чтобы ощутить городскую элегантность.',
            uz:"Ozarbayjon poytaxti Boku bilan zamonaviy arxitekturani tarixiy jozibasi bilan uyg'unlashtirgan. Mashhur olov minoralari, Haydar Aliyev markazi va yaqinda qurilgan Boku Olimpiya stadioni mamlakatning zamonaviy qarashlarini aks ettiruvchi meʼmoriy durdonadir. Kaspiy dengizi fonida zamonaviy osmono'par binolarning hayratlanarli kontrastiga guvoh bo'ling va shaharning shahar nafisligini tatib ko'rish uchun Boku bulvari deb nomlanuvchi qirg'oq bo'ylab sayr qiling."
        },
            img_list:[
            {
                id:"11",
                img:boku1
            },
            {
                id:"12",
                img:boku2
            },
            {
                id:"13",
                img:boku3
            },
            {
                id:"14",
                img:boku4
            },
        ],
        price:8500000,
        recom_title:{
            en:"Tour of Azerbaijan",
            ru:"Tур по Азербайджан",
            uz:"Ozarbayjonga sayohati"
        }, 
        location:{
            en:"Baku, Azerbaijan",
            ru:"баку, азербайджан",
            uz:"Boku, Ozarbayjon"
        },
        text:{
            en:"Make a stop on the shores of the Caspian Sea or go on an active hike in the Caucasus Mountains. Azerbaijan is a quaint country with many interesting attractions.",
            ru:"Сделайте остановку на берегу Каспийского моря или отправьтесь в активный поход по Кавказским горам. Азербайджан — причудливая страна с множеством интересных достопримечательностей.",
            uz:"Kaspiy dengizi qirg'og'ida to'xtang yoki Kavkaz tog'larida faol sayohatga chiqing. Ozarbayjon ko'plab qiziqarli diqqatga sazovor joylarga ega g'alati mamlakat."
        },
        img:baku,
        country:{
            en:"Azerbaijan",
            ru:"азербайджан",
            uz:"Ozarbayjon"
        },
        city:{
            en:"Baku",
            ru:"баку",
            uz:"Boku"
        },
        h_img:hboku,
        slug:"/ozarbayjon"
    },
    {
        id:7,
        title:{
            en:"Uzbekistan",
            ru:"узбекистан",
            uz:"O'zbekiston"
        },
        link_title:{
            en:"tour to uzbekistan",
            ru:"тур по узбекистан",
            uz:"o'zbekistonga sayohat"
        },
        sup_text:{
            en:"Uzbekistan, a land of rich history, vibrant culture, and breathtaking landscapes, offers travelers a unique and immersive travel experience. Located at the heart of the ancient Silk Road, Uzbekistan is a treasure trove of architectural wonders, UNESCO World Heritage Sites, and warm hospitality.",
            ru:"Узбекистан, страна богатой истории, яркой культуры и захватывающих дух пейзажей, предлагает путешественникам уникальные и захватывающие впечатления от путешествий. Расположенный в самом сердце древнего Шелкового пути, Узбекистан является сокровищницей архитектурных чудес, объектов Всемирного наследия ЮНЕСКО и теплого гостеприимства. ",
            uz:"O'zbekiston boy tarix, jo'shqin madaniyat va hayratlanarli landshaftlarga ega mamlakat sayohatchilarga noyob va hayratlanarli sayohat tajribasini taqdim etadi. Qadimgi Ipak yoʻlining markazida joylashgan Oʻzbekiston meʼmoriy moʻjizalar xazinasi, YuNESKOning Butunjahon merosi obʼyektlari va samimiy mehmondoʻstlikdir."
        },
        subtitle:{
            en:'The unique heritage of the <span style="color:#7000FF">Silk Road</span>',
            ru:'Уникальное наследие <span style="color:#7000FF">Шелкового пути</span>',
            uz:`<span style="color:#7000FF">Ipak yo'li</span>ning noyob merosi`
        },
        sub_text:{
            en:"Being a pivotal point along the Silk Road, Uzbekistan is steeped in history and heritage. The Silk Road was a trade route that connected the East and West, facilitating the exchange of goods, ideas, and cultures. Visiting Uzbekistan allows you to walk in the footsteps of traders and explorers who once traversed this ancient route. Explore caravanserais, ancient trading posts, and archaeological sites that bear witness to the cross-cultural exchanges that occurred along the Silk Road.",
            ru:'Будучи ключевым пунктом на Шелковом пути, Узбекистан имеет богатую историю и наследие. Шелковый путь был торговым маршрутом, который соединял Восток и Запад, облегчая обмен товарами, идеями и культурами. Посещение Узбекистана позволит вам пройти по следам торговцев и исследователей, которые когда-то прошли этот древний маршрут. Исследуйте караван-сараи, древние торговые фактории и археологические памятники, свидетельствующие о межкультурных обменах, происходящих на Шелковом пути.',
            uz:"Ipak yoʻlining asosiy nuqtasi boʻlgan Oʻzbekiston tarix va merosga boy. Ipak yoʻli Sharq va Gʻarbni bogʻlovchi, tovarlar, gʻoyalar va madaniyatlar almashinuvini osonlashtirgan savdo yoʻli edi. O‘zbekistonga tashrif buyurish bir paytlar ushbu qadimiy yo‘lni bosib o‘tgan savdogarlar va tadqiqotchilar izidan yurish imkonini beradi. Ipak yo‘li bo‘ylab sodir bo‘lgan madaniyatlararo almashinuvlardan guvohlik beruvchi karvonsaroylar, qadimiy savdo nuqtalari va arxeologik obidalarni o‘rganing."
        },
        img_list:[
            {
                id:"11",
                img:uzbek1
            },
            {
                id:"12",
                img:uzbek2
            },
            {
                id:"13",
                img:uzbek3
            },
            {
                id:"14",
                img:uzbek4
            },
        ],
        price:20000000,
        recom_title:{
            en:"Tour to uzbekistan",
            ru:"Tур по узбекистан",
            uz:"o'zbekistonga sayohat"
        },
        location:{
            en:"Tashkent, Uzbekistan",
            ru:"ташкент, узбекистан",
            uz:"Toshkent, Oʻzbekiston"
        },
        text:{
            en:"Uzbekistan is famous for its silk production and majestic palaces. This enchanting country is an experience not to be missed for any culture lover.",
            ru:"Узбекистан известен производством шелка и величественными дворцами. Эта очаровательная страна — опыт, который нельзя пропустить для каждого любителя культуры. ",
            uz:"O‘zbekiston o‘zining ipakchilik ishlab chiqarishi va mahobatli saroylari bilan mashhur. Bu maftunkor mamlakat har qanday madaniyat ixlosmandlari uchun o'tkazib yuborilmaydigan tajribadir."
        },
        img:toshkent,
        country:{
            en:"Uzbekistan",
            ru:"узбекистан",
            uz:"O'zbekiston"
        },
        city:{
            en:"Tashkent",
            ru:"ташкент",
            uz:"Toshkent"
        },
        h_img:htoshkent,
        slug:"/uzbekistan"
    },
    {
        id:8,
        title:{
            en:"Thailand",
            ru:"Таиланд",
            uz:"Tailand"
        },
        link_title:{
            en:"tour to Thailand",
            ru:"тур по Таиланд",
            uz:"Tailandga sayohat"
        },
        sup_text:{
            en:"Known as the “Land of Smiles,” Thailand is an enchanting destination offering a unique combination of natural beauty, rich cultural heritage, warm hospitality and culinary delights. Thailand has something to offer every traveler, from stunning tropical beaches and vibrant cityscapes to ancient temples and cultural traditions.",
            ru:"Таиланд, известный как «Страна улыбок», — это очаровательное место, предлагающее уникальное сочетание природной красоты, богатого культурного наследия, теплого гостеприимства и кулинарных изысков. Таиланду есть что предложить каждому путешественнику: от потрясающих тропических пляжей и ярких городских пейзажей до древних храмов и культурных традиций. ",
            uz:"“Tabassumlar mamlakati” sifatida tanilgan Tailand tabiiy go'zallik, boy madaniy meros, samimiy mehmondo'stlik va oshpazlik lazzatlarining o'ziga xos uyg'unligini taklif qiluvchi jozibali manzildir. Tailandda har bir sayohatchiga ajoyib tropik plyajlar va jonli shahar manzaralaridan tortib qadimiy ibodatxonalar va madaniy an'analargacha taklif qiladigan narsa bor."
        },
        subtitle:{
            en:'Rich cultural <span style="color:#7000FF">heritage</span> and <span style="color:#7000FF">temples</span>',
            ru:'Богатое культурное <span style="color:#7000FF">наследие</span> и <span style="color:#7000FF">храмы</span>',
            uz:`Boy madaniy <span style="color:#7000FF">meros</span> va <span style="color:#7000FF">ibodatxonalar</span>`
        },
        sub_text:{
            en:"Thailand has a rich cultural heritage that is evident in its magnificent temples, festivals and traditional customs. Visit the Grand Palace and the sacred temple of Wat Arun in Bangkok, admire the ancient ruins of Ayutthaya, or immerse yourself in the spiritual atmosphere of Chiang Mai's many temples. Visit vibrant festivals such as Songkran (Thai New Year) and Loy Krathong, where you can witness traditional dances, processions and rituals that showcase Thailand's cultural diversity.",
            ru:'Таиланд имеет богатое культурное наследие, которое проявляется в его великолепных храмах, фестивалях и традиционных обычаях. Посетите Большой дворец и священный храм Ват Арун в Бангкоке, полюбуйтесь древними руинами Аюттхая или погрузитесь в духовную атмосферу многочисленных храмов Чиангмая. Посетите яркие фестивали, такие как Сонгкран (Тайский Новый год) и Лой Кратонг, где вы сможете стать свидетелем традиционных танцев, шествий и ритуалов, демонстрирующих культурное разнообразие Таиланда.',
            uz:"Tailand o'zining ajoyib ibodatxonalari, festivallari va an'anaviy urf-odatlarida yaqqol namoyon bo'lgan boy madaniy merosga ega. Bangkokdagi Buyuk saroy va muqaddas Wat Arun ibodatxonasiga tashrif buyuring, Ayutthayaning qadimiy xarobalari bilan qoyil qoling yoki Chiang Mayning ko'plab ibodatxonalaridagi ruhiy muhitga sho'ng'ing. Songkran (Tailand Yangi Yili) va Loy Krathong kabi jonli festivallarga tashrif buyuring, u erda Tailandning madaniy xilma-xilligini namoyish qiluvchi an'anaviy raqslar, yurishlar va marosimlarga guvoh bo'lishingiz mumkin."
        },
        img_list:[
            {
                id:"11",
                img:tailand1
            },
            {
                id:"12",
                img:tailand2
            },
            {
                id:"13",
                img:tailand3
            },
            {
                id:"14",
                img:tailand4
            },
        ],
        price:8700000,
        recom_title:{
            en:"Tour to Thailand",
            ru:"Tур по Таиланд",
            uz:"Tailandga sayohat"
        },
        location:{
            en:"Bangkok, Thailand",
            ru:"Бангкок, Таиланд",
            uz:"Bangkok, Tailand"
        },
        text:{
            en:`Thailand, also known as "Strana ulybok", is a charming city that offers a unique combination of natural beauty, rich cultural heritage, warm hospitality and culinary skills.`,
            ru:"Таиланд, известный как «Страна улыбок», — это очаровательное место, предлагающее уникальное сочетание природной красоты, богатого культурного наследия, теплого гостеприимства и кулинарных изысков.",
            uz:"“Tabassumlar mamlakati” sifatida tanilgan Tailand tabiiy go'zallik, boy madaniy meros, samimiy mehmondo'stlik va oshpazlik lazzatlarining o'ziga xos uyg'unligini taklif qiluvchi jozibali manzildir."
        },
        img:tailand,
        country:{
            en:"Thailand",
            ru:"Таиланд",
            uz:"Tailand"
        },
        city:{
            en:"Bangkok",
            ru:"Бангкок",
            uz:"Bangkok"
        },
        h_img:tailand,
        slug:"/thailand"
    },
    {
        id:9,
        title:{
            en:"Georgia",
            ru:"Грузия",
            uz:"Gruziya"
        },
        link_title:{
            en:"tour to Georgia",
            ru:"тур по Грузия",
            uz:"Gruziyaga sayohat"
        },
        sup_text:{
            en:"Georgia, located in the Caucasus region, is a fascinating country that offers the perfect combination of natural beauty, rich history, warm hospitality and unique cultural experiences. Georgia has something to entice every traveler, from stunning mountain scenery and ancient UNESCO World Heritage sites to vibrant cities and delicious cuisine.",
            ru:"Грузия, расположенная в Кавказском регионе, является очаровательной страной, которая предлагает идеальное сочетание природной красоты, богатой истории, теплого гостеприимства и уникального культурного опыта. В Грузии есть чем соблазнить каждого путешественника: от потрясающих горных пейзажей и древних объектов всемирного наследия ЮНЕСКО до ярких городов и вкусной кухни.",
            uz:"Kavkaz mintaqasida joylashgan Gruziya tabiiy go'zallik, boy tarix, samimiy mehmondo'stlik va noyob madaniy tajribalarning mukammal uyg'unligini taklif qiluvchi ajoyib mamlakatdir. Gruziya har bir sayohatchini o'ziga jalb qiladigan narsaga ega: ajoyib tog' manzaralari va qadimgi YuNESKOning Jahon merosi ob'ektlaridan tortib jonli shaharlar va mazali taomlargacha."
        },
        subtitle:{
            en:'Picturesque <span style="color:#7000FF">landscapes</span>',
            ru:'Живописные <span style="color:#7000FF">пейзажи</span>',
            uz:`Go'zal <span style="color:#7000FF">manzaralar</span>`
        },
        sub_text:{
            en:"Georgia boasts breathtaking natural landscapes that will leave you in awe. Explore the majestic peaks of the Caucasus Mountains, including Mount Kazbek and Mount Ushba, which offer exciting hiking and trekking opportunities. Discover the beauty of the Georgian countryside with its lush green valleys, pristine lakes and picturesque vineyards. From the dramatic landscapes of Svaneti to the serene beauty of Lake Ritsa, Georgia's natural wonders are sure to mesmerize you.",
            ru:'Грузия может похвастаться захватывающими дух природными пейзажами, которые оставят вас в восторге. Исследуйте величественные вершины Кавказских гор, в том числе горы Казбек и Ушба, которые предлагают увлекательные возможности для пеших прогулок и треккинга. Откройте для себя красоту сельской местности Грузии с ее пышными зелеными долинами, нетронутыми озерами и живописными виноградниками. От драматических пейзажей Сванетии до безмятежной красоты озера Рица — чудеса природы Грузии обязательно загипнотизируют вас.',
            uz:"Gruziya sizni hayratda qoldiradigan hayratlanarli tabiiy landshaftlarga ega. Kavkaz tog'larining ulug'vor cho'qqilarini, jumladan, Kazbek tog'i va Ushba tog'larini o'rganing, ular hayajonli sayohat va trekking imkoniyatlarini taqdim etadi. Yam-yashil vodiylari, beg'ubor ko'llari va go'zal uzumzorlari bilan Gruziya qishloqlarining go'zalligini kashf eting. Svaneti dramatik landshaftlaridan Ritsa ko'lining sokin go'zalligigacha, Gruziyaning tabiiy mo''jizalari sizni hayratda qoldirishi aniq."
        },
        img_list:[
            {
                id:"11",
                img:gruziya1
            },
            {
                id:"12",
                img:gruziya2
            },
            {
                id:"13",
                img:gruziya3
            },
            {
                id:"14",
                img:gruziya4
            },
        ],
        price:15000000,
        recom_title:{
            en:"tour to Georgia",
            ru:"тур по Грузия",
            uz:"Gruziyaga sayohat"
        },
        location:{
            en:"Tbilisi, Georgia",
            ru:"Тбилиси, Грузия",
            uz:"Tbilisi, Gruziya"
        },
        text:{
            en:`Georgia, located in the Caucasus region, is a fascinating country that offers the perfect combination of natural beauty, rich history, warm hospitality and unique cultural experiences.`,
            ru:"Грузия, расположенная в Кавказском регионе, является очаровательной страной, которая предлагает идеальное сочетание природной красоты, богатой истории, теплого гостеприимства и уникального культурного опыта.",
            uz:"Kavkaz mintaqasida joylashgan Gruziya tabiiy go'zallik, boy tarix, samimiy mehmondo'stlik va noyob madaniy tajribalarning mukammal uyg'unligini taklif qiluvchi ajoyib mamlakatdir."
        },
        img:gruziya,
        country:{
            en:"Georgia",
            ru:"Грузия",
            uz:"Gruziya"
        },
        city:{
            en:"Tbilisi",
            ru:"Тбилиси",
            uz:"Tbilisi"
        },
        h_img:gruziya,
        slug:"/georgia"
    }


]

export {locations}
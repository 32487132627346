import React, { useRef } from 'react'
import Slider from 'react-slick'
import { begin } from '../../db/beginDb';
import NextArrow from '../../components/NextArrow';
import PrevArrow from '../../components/PrevArrow';
import { Link } from 'react-router-dom';
import { TranslateText } from '../../context/TranslateText';

const Begin = () => {
    const sliderRef = useRef(null)
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const count = window.innerWidth > 768 ? 3 : window.innerWidth > 540 ? 2 : 1
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: 1,
    variableWidth: true,
    // responsive: [
    //   {
    //     breakpoint: 540,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
    nextArrow: <NextArrow onClick={handleNext} className3='slick-begin-next' />,
    prevArrow: <PrevArrow onClick={handlePrev} className3='slick-begin-prev' />
  };

  return (
    <div className='max-w-[1440px] mx-auto max-h-[1080px] overflow-hidden'>
        <div className='container  md:mt-[160px] mt-[60px]  grid lg:grid-cols-4 grid-cols-1 md:gap-[31px] gap-0 lg:gap-y-0 gap-y-[30px]'>
            <div className=''>
                <h2 className='font-ibm 2xl:text-[40px] xl:text-[36px] text-[28px] font-semibold'>
                  <TranslateText dictionary={{
                    en:"Start your adventure with ",
                    ru:"Начните свое приключение с ",
                    uz:"Sayohatingizni "
                  }} />
                  <span className='text-active-purple'>Cosmos Tour</span>
                  <TranslateText dictionary={{
                    en:"",
                    ru:"",
                    uz:" bilan boshlang"
                  }} />
                </h2>
                <p className='font-montserrat text-main xl:text-[21px] text-base my-[20px]'>
                  <TranslateText dictionary={{
                    en:"Planning a trip can be a daunting task, but with the right travel agency, the process will be smooth, enjoyable and guaranteed to give you an experience of a lifetime.",
                    ru:"Планирование поездки может оказаться непростой задачей, но с правильным туристическим агентством этот процесс станет гладким, приятным и гарантирует, что вы получите незабываемые впечатления на всю жизнь. ",
                    uz:"Sayohatni rejalashtirish juda qiyin vazifa bo'lishi mumkin, ammo to'g'ri sayyohlik agentligi bilan jarayon silliq, yoqimli va sizga umr bo'yi tajriba taqdim etishi kafolatlanadi."
                  }} />
                
                </p>
                <Link to="/about" className='bg-active-purple font-montserrat font-medium xl:text-[16px] text-[14px] capitalize text-white xl:px-[33px] px-[23px] py-[11px] rounded-full'>
                  <TranslateText 
                    dictionary={{
                      en:"detailed about us",
                      ru:"подробное о нас",
                      uz:"biz haqimizda batafsil"
                    }}
                  />

                
                </Link>
            </div>
            <div className='col-span-3'>
            <Slider ref={sliderRef} {...settings} className='capitalize begin  w-full font-ibm relative py-2'>
                {
                    begin.map((item) => (
                    <Link to="/about" key={item.id} className='shadow-[0_3px_10px_rgb(0,0,0,0.2)] relative lg:max-h-[660px]  max-h-[600px] card overflow-hidden bg-white flex flex-col justify-between rounded-b-lg'>

                        <img src={item.img_url} alt='misr_image' className='w-full bg-cover bg-no-repeat bg-bottom rounded-b-lg' />
                        <h3 className='text-[21px] w-full absolute bottom-0 text-white p-[20px] text-center font-medium font-ibm capitalize'>{<TranslateText dictionary={item.title} />}</h3>
                    <div className='p-[20px] absolute bottom-0 sm:translate-y-[100%] translate-y-0 card-down bg-main  text-white text-center rounded-[10px] w-full'>
                        <h3 className='md:text-[21px] text-base font-medium font-ibm capitalize'>{<TranslateText dictionary={item.title} />}</h3>
                        <p className='font-montserrat text-[14px] font-normal my-[12px] leading-normal sm:block hidden'>{<TranslateText dictionary={item.text} />}</p>
                        <button className='font-montserrat font-medium md:text-[16px] text-[14px] capitalize md:text-white text-smooth md:px-[60px] px-[30px] md:py-[12px] py-0  md:border-2 border-0 rounded-full md:mb-[20px] mb-0 md:mt-[10px] mt-[40px]'><TranslateText dictionary={{en:"detailed", ru:"подробное", uz:"batafsil"}} /></button>
                    </div>
                    </Link>
                    ))
                }
        </Slider>
            </div>
        </div>
    </div>
  )
}

export {Begin}

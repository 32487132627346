import img1 from "../assets/images/begin/img1.png"
import img2 from "../assets/images/begin/img2.png"
import img3 from "../assets/images/begin/img3.png"
import img4 from "../assets/images/begin/img4.png"

const begin = [
    {
        id:1,
        title:{
            en:"Unforgettable impressions",
            ru:"Незабываемые впечатления",
            uz:"Unutilmas taassurotlar"
        },
        text:{
            en:"Our routes are designed to immerse you in the heart of each....",
            ru:"Наши маршруты разработаны так, чтобы погрузить вас в самое сердце каждого .... ",
            uz:"Bizning marshrutlarimiz sizni har birining yuragiga cho'mdirish uchun yaratilgan...."
        },
        img_url:img1,
        slug:""
    },
    {
        id:2,
        title:{
            en:"Best guides",
            ru:"Лучшие гиды",
            uz:"Eng yaxshi qo'llanmalar"
        },
        text:{
            en:"Our engaging and experienced guides can turn a great holiday...",
            ru:"Наши увлекательные и опытные гиды могут превратить отличный отдых... ",
            uz:"Bizning qiziqarli va tajribali gidlarimiz ajoyib bayramga aylanishi mumkin..."
        },
        img_url:img2,
        slug:""
    },
    {
        id:3,
        title:{
            en:"Unrivaled convenience and simplicity",
            ru:"Непревзойденное удобство и простота",
            uz:"Raqobatsiz qulaylik va soddalik"
        },
        text:{
            en:"From professionally planned routes and transport arrangements...",
            ru:"От профессионально спланированных маршрутов и организации транспорта ...",
            uz:"Professional tarzda rejalashtirilgan marshrutlardan va transport tadbirlaridan..."
        },
        img_url:img3,
        slug:""
    },
    {
        id:4,
        title:{
            en:"Uncompromising quality and value",
            ru:"Бескомпромиссное качество и ценность",
            uz:"Murosasiz sifat va qiymat"
        },
        text:{
            en:"At Cosmos we understand that quality and cost are paramount....",
            ru:"В Cosmos мы понимаем, что качество и стоимость являются важнейшими.... ",
            uz:"Kosmosda biz sifat va narx muhimligini tushunamiz...."
        },
        img_url:img4,
        slug:""
    }
]

export {begin}
import React from 'react'
import { Nav } from '../../components/Nav'
import { Footer } from '../../components/footer/footer'

const ContactPage = () => {
  return (
    <div>
        <Nav version={2} />
           <div className='max-w-[1440px] mx-auto'>
                <div className='container'>
                    <h2 className='font-ibm lg:text-[40px] sm:text-[30px] text-[21px] font-semibold sm:mb-[20px] mb-[10px] sm:mt-[40px] mt-[40px]'>Наши <span className='text-active-purple '>контакты</span></h2>
                    <p className='font-montserrat text-main lg:text-[21px] sm:text-[18px] text-base'>У вас есть вопрос, предложение или отзыв для нас? Позвоните нам или напишите нам, чтобы связаться с нашей командой.</p>
                    <div className='font-montserrat flex md:flex-row flex-col md:justify-around justify-center md:items-start items-center md:gap-0 gap-[40px] mt-[70px] mb-[42px]'>
                        <div className='flex flex-col justify-center items-center max-w-[470px] gap-[12px] '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" fill="none" className='bg-active-purple p-[14px] rounded-full'>
                                <path d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z" fill="white"/>
                            </svg>
                            <h3 className='font-semibold text-[16px]'>Телефон номер</h3>
                            <p className='text-[21px] font-normal'>+998 97 795 72 22</p>
                        </div>
                        <div className='flex flex-col justify-center items-center lg:max-w-[470px] md:max-w-[300px] max-w-[350px] gap-[12px]'>
                            <svg width="52" height="52" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className='bg-active-purple p-[14px] rounded-full'>
                                <g id="Frame">
                                <path id="Vector" d="M16.364 15.364L10 21.7279L3.63604 15.364C0.12132 11.8492 0.12132 6.15076 3.63604 2.63604C7.15076 -0.87868 12.8492 -0.87868 16.364 2.63604C19.8787 6.15076 19.8787 11.8492 16.364 15.364ZM10 13C12.2091 13 14 11.2091 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9C6 11.2091 7.79086 13 10 13ZM10 11C8.8954 11 8 10.1046 8 9C8 7.89543 8.8954 7 10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11Z" fill="white"/>
                                </g>
                            </svg>
                            <h3 className='font-semibold text-[16px] capitalize'>адрес</h3>
                            <p className='text-[21px] font-normal px-8 text-center'>Навоийская область, Кармана район, Кахрамон МФЙ, улица Алмазор, 33-дом</p>
                        </div>
                        <div className='flex flex-col justify-center items-center max-w-[470px] gap-[12px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" fill="none" className='bg-active-purple p-[14px] rounded-full'>
                                <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829Z" fill="white"/>
                            </svg>
                            <h3 className='font-semibold text-[16px] capitalize'>почта</h3>
                            <p className='text-[21px] font-normal'>info@cosmostravel.uz</p>
                        </div>
                    </div>
                </div>
            </div> 
                <div className='md:h-[550px] h-[300px] '>
                    <iframe className='google-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.0422180869496!2d65.3647966387765!3d40.09736089872284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f51c6c63f648825%3A0xda4bfccbb94f19ba!2sKAMILLA!5e0!3m2!1sen!2s!4v1689952995011!5m2!1sen!2s" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
        <Footer />
    </div>
  )
}

export {ContactPage}
